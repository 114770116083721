<!-- 预警通知-查看详情 -->

<template>
  <div class="noticeView">
    <back />
    <div class="globle_border box" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="codeValue" placeholder="请搜索商品编号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-button class="btn" type="primary" @click="handleAddMultiple">批量补货</el-button>

      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="序号" min-width="80" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="supplierName" label="供应商名称" show-overflow-tooltip></el-table-column> -->
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip></el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="150"
            align="center"></el-table-column>
          <el-table-column prop="specs" label="商品规格" show-overflow-tooltip min-width="120"
            align="center"></el-table-column>
          <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip min-width="150"
            align="center"></el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="100"
            align="center"></el-table-column>
          <el-table-column show-overflow-tooltip prop="warnType" label="通知类型" min-width="100" align="center">
            <template slot-scope="scope">
              <span>{{ scope.row.warnType == '1' ? '缺货预警' : '收货预警' }}</span>
            </template>
          </el-table-column>
          <!-- 收货预警 -->
          <el-table-column v-if="type == 2" prop="warnMsg" label="内容" show-overflow-tooltip min-width="110"
            align="center"></el-table-column>

          <el-table-column prop="addCount" label="建议增加库存" show-overflow-tooltip min-width="130"
            align="center"></el-table-column>
          <el-table-column prop="createdTime" label="时间" show-overflow-tooltip min-width="180"
            align="center"></el-table-column>
          <el-table-column label="操作" header-align="center" show-overflow-tooltip min-width="180" align="center"
            fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleAdd(scope.row)">补货</el-button>
              <el-button size="small" class="btn" type="" plain @click="handleIgnore(scope.row)">忽略</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>
    <!-- 批量补货供应商弹窗 -->
    <el-dialog title="商品补货" :visible.sync="centerDialogVisible" width="60%">
      <div class="globle_table">
        <el-table ref="multipleTable" :data="dialogTableData" @select="handleChangeCurrentRow" style="width: 100%"
          max-height="540">
          <el-table-column type="selection" align="center" width="60"></el-table-column>
          <el-table-column property="supplierName" label="供应商名称" min-width="120" align="center">
          </el-table-column>
          <el-table-column property="contacts" label="联系人" min-width="120" align="center">
          </el-table-column>
          <el-table-column property="telphone" label="联系电话" min-width="120" align="center">
          </el-table-column>
          <el-table-column property="supplyPrice" label="供货价(元)" min-width="100" align="center">
          </el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleCommit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      type: '',
      startDate: '',
      endDate: '',
      productValue: '', // 请搜索商品名称
      codeValue: '', // 请搜索商品编号
      barCode: '', // 请搜索商品条形码
      // 表格数据
      tableData: [],
      multipleSelection: [], // 选中数据
      centerDialogVisible: false,//弹窗
      dialogTableData: [],//弹窗数据
      currentRow: null,//弹窗选中
      listRow: null,//弹窗打开后原列表选择的数据
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.type = this.$route.query.type || null;
    this.startDate = this.$route.query.startDate || null;
    this.endDate = this.$route.query.endDate || null;
    this.getWarningNoticeSingleList()
  },
  methods: {
    // 获取商品列表
    getWarningNoticeSingleList() {
      this.loading = true;
      this.$axios.get(this.$api.getWarningNoticeSingleList, {
        params: {
          productName: this.productValue || null,  // 搜索商品名称
          serialId: this.codeValue || null,  // 请搜索商品编号
          barCode: this.barCode || null,  // 请搜索商品条形码
          warnType: this.type,  // 预警类型
          startDate: this.startDate,  // 开始时间
          endDate: this.endDate,  // 结束时间
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 加入订单
    joinOrder() {
      const list = this.multipleSelection.map(item => {
        return {
          productId: Number(item.productId),
          quantity: item.addCount,
          supplierId: item.supplierId,
          enterBox: item.enterBox,
        }
      });
      this.$axios.post(this.$api.purchaseCartSave, {
        ifSaveList: 1,//是否批量,0否，1是
        type: 1,//购物车类型:1.普通采购,2套餐采购
        productList: list,
        shopId: this.$store.state.userInfo.shopId, //shopId
      }).then(() => {
        this.$message.success(`操作成功`);
        this.getWarningNoticeSingleList()
      })
    },
    // 批量补货
    handleAddMultiple() {
      if (this.multipleSelection.length === 0) {
        this.$message.warning('请先选择商品！');
        return;
      }
      const ids = this.multipleSelection.map(item => item.id);
      this.$confirm(`确定批量补货吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      }).then(() => {
        this.$axios.put(this.$api.deleteShopWarningMsg, ids
        ).then(() => {
        }).catch(() => {
          this.$message.error(`操作失败`);
          this.getWarningNoticeSingleList()
        }).finally(() => {
          this.joinOrder();
        });
      }).catch(() => {
        this.$message('已取消该操作！');
      })
    },
    // 补货弹窗选中数据
    handleChangeCurrentRow(row) {
      // 清空所有选中并只获取一条数据
      if (row.length > 1) {
        this.$refs.multipleTable.clearSelection()
        this.$refs.multipleTable.toggleRowSelection(row[1])
      }
      this.currentRow = row[1] || row[0];
    },
    // 补货弹窗确认
    handleCommit() {
      if (this.currentRow == null) {
        this.$message.warning('请先勾选供应商！');
        return;
      }
      // console.log('选中的供应商', this.currentRow);
      this.centerDialogVisible = false
      this.$axios.put(this.$api.deleteShopWarningMsg, [this.listRow.id]
      )
        .then((res) => {
          if (res.data.code == 100) {
            this.$message.success(res.data.desc)
          }
          this.getWarningNoticeSingleList()
        }).catch(() => { }).finally(() => {
          const list = [
            {
              productId: Number(this.listRow.productId),
              quantity: this.listRow.addCount,
              supplierId: this.currentRow.supplierId,
              supplyPrice: this.currentRow.supplyPrice,
              enterBox: this.listRow.enterBox,
            }
          ]
          this.$axios.post(this.$api.purchaseCartSave, {
            ifSaveList: 0,	//是否批量,0否，1是
            productList: list,
            shopId: this.$store.state.userInfo.shopId, //shopId
            type: 1,//购物车类型:1.普通采购,2套餐采购
          }).then(() => {
          })
        });
    },
    //忽略
    handleIgnore(row) {
      this.$confirm(`确定要忽略该商品的${row.warnType == '1' ? '缺货预警' : '收货预警'}吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(this.$api.deleteShopWarningMsg, [row.id]
        )
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getWarningNoticeSingleList()
          }).catch(() => { }).finally(() => {
            const list = [
              {
                productId: Number(row.productId),
                quantity: row.addCount,
                supplierId: row.supplierId,
                enterBox: row.enterBox,
              }
            ]
            this.$axios.post(this.$api.purchaseCartSave, {
              productList: list,
              shopId: this.$store.state.userInfo.shopId, //shopId
            }).then(() => {
            })
          });
      }).catch(() => {
        this.$message('已取消该操作！');
      })
    },
    //补货弹窗
    handleAdd(row) {
      this.currentRow = null
      this.listRow = null
      this.centerDialogVisible = true
      this.listRow = row
      this.$nextTick(() => {
        this.$refs.multipleTable.clearSelection()//清空所有勾选
      })
      // 获取供应商列表
      const getSupplierByProductIdUrl = `${this.$api.getSupplierByProductId}?productId=${row.productId}`
      this.$axios.get(getSupplierByProductIdUrl).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.dialogTableData = result
        }
      }).catch((err) => {
        console.log(err);
      })

    },
    // 选中
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 下拉选择
    handleSelect(value) {
      this.clearData();
      this.shelfValue = value;
      this.getWarningNoticeSingleList();
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getWarningNoticeSingleList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getWarningNoticeSingleList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getWarningNoticeSingleList();
    },

  }

}



</script>

<style scoped lang="scss">
.noticeView {
  .box {
    padding-top: 0px;


  }

  ::v-deep .el-table__header-wrapper .el-table__header .el-checkbox {
    display: none;
  }

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
      margin-left: 10px;
    }

    .el-select,
    .el-cascader,
    .el-button {
      margin-left: 10px;
    }

  }
}
</style>
